import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e1977fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("textarea", _mergeProps({
    class: ["autosize-textarea", { error: $props.validationError }],
    ref: "textarea",
    value: $props.modelValue,
    placeholder: $props.placeholder,
    style: $setup.cssVars
  }, _ctx.$attrs, {
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
    onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus'))),
    onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur')))
  }), null, 16, _hoisted_1))
}