import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d000df6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "mentions--wrapper",
  ref: "el"
}
const _hoisted_2 = { class: "mentions--list" }
const _hoisted_3 = {
  key: 0,
  class: "mentions--empty"
}
const _hoisted_4 = {
  key: 0,
  class: "mentions--company-name"
}
const _hoisted_5 = ["onMouseover", "onMousedown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (!!$setup.currentKey)
      ? (_openBlock(), _createBlock($setup["NScrollbar"], {
          key: 0,
          class: "mentions--block"
        }, {
          default: _withCtx(() => [
            _createElementVNode("ul", _hoisted_2, [
              (!$setup.displayedItems.length)
                ? (_openBlock(), _createElementBlock("li", _hoisted_3, "No results. Please try a different search."))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($setup.displayedItems, (item, index) => {
                    return (_openBlock(), _createElementBlock("ul", {
                      class: "mentions--company-member-list",
                      key: item.key
                    }, [
                      (index === 0 || item.companyName !== $setup.displayedItems[index-1].companyName)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(item.companyName), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("li", {
                        class: _normalizeClass(["mentions--item", {'mentions--item-active': index === $setup.selectedIndex}]),
                        onMouseover: ($event: any) => ($setup.selectedIndex = index),
                        onMousedown: ($event: any) => ($setup.applyMention(index))
                      }, [
                        _createVNode($setup["UserAvatar"], {
                          class: "mentions--avatar",
                          id: item.avatar,
                          size: 24,
                          name: item.label
                        }, null, 8, ["id", "name"]),
                        _createTextVNode(_toDisplayString(item.label), 1)
                      ], 42, _hoisted_5)
                    ]))
                  }), 128))
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 512))
}