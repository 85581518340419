import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17f4c6c5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "report--wrapper" }
const _hoisted_2 = { class: "report--name" }
const _hoisted_3 = { class: "report--info" }
const _hoisted_4 = {
  key: 0,
  class: "report--date"
}
const _hoisted_5 = { class: "report--actions" }
const _hoisted_6 = ["onChange"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.reports, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "report--item",
        key: item.id
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(item.reportType), 1),
        _createElementVNode("div", _hoisted_3, [
          (item.updatedAt)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Updated " + _toDisplayString($setup.dayjs(item.updatedAt).format($setup.defaultDateFormatTemplate)), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            (item.fileId)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode($setup["NButton"], {
                    class: "action-button",
                    quaternary: "",
                    bordered: false,
                    onClick: ($event: any) => ($setup.openFile(item.fileId))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("View")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode($setup["NButton"], {
                    class: "action-button",
                    quaternary: "",
                    bordered: false,
                    onClick: ($event: any) => ($setup.deleteModalOpen(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Delete")
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ], 64))
              : (_openBlock(), _createBlock($setup["NButton"], {
                  key: 1,
                  class: "action-button",
                  quaternary: "",
                  bordered: false
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("label", null, [
                      _createTextVNode("Upload"),
                      _createElementVNode("input", {
                        type: "file",
                        name: "file",
                        onChange: ($event: any) => ($setup.onFileSelected($event, item.reportType))
                      }, null, 40, _hoisted_6)
                    ])
                  ]),
                  _: 2
                }, 1024))
          ])
        ])
      ]))
    }), 128)),
    ($setup.showDeleteModal)
      ? (_openBlock(), _createBlock($setup["DefaultModal"], {
          key: 0,
          class: "delete-modal delete-report-modal",
          show: $setup.showDeleteModal,
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (($setup.showDeleteModal) = $event)),
          text: `Are you sure you want to delete this ${$setup.reportForDelete.reportType}${$setup.returnReportText($setup.reportForDelete.reportType)}?`,
          title: `Delete ${$setup.reportForDelete.reportType}${$setup.returnReportText($setup.reportForDelete.reportType)}`,
          actionText: "Delete",
          onAction: $setup.onDeleteFile,
          onClose: $setup.onDeleteModalClose
        }, null, 8, ["show", "text", "title"]))
      : _createCommentVNode("", true)
  ]))
}