import { Ref } from "vue/dist/vue";
import QuoteService from "@/services/QuoteService";
import OrderInterface from "@/types/OrderInterface";

export const getOrderDetailPartPreview = (
  order: Ref<OrderInterface>,
  partIdList: string[]
) => {
  return QuoteService.getPartPreviews(partIdList).then((res) => {
    const orderPreview = res.data.data.find(
      (preview: { partId: string }) => preview.partId === order.value.partId?.id
    );
    order.value.thumbnailUrl = orderPreview?.link || "";
  });
};
