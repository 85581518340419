import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45bd4b6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "open-orders track-orders--table" }
const _hoisted_2 = { class: "flex-wrapper" }
const _hoisted_3 = { class: "open-orders-title order-details-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString($setup.openOrdersMessage), 1),
      _createElementVNode("div", {
        class: "open-orders-toggle",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:showOpenOrders', false)))
      }, "Close")
    ]),
    _createVNode($setup["NDataTable"], {
      class: "track-orders--table order-line-table",
      columns: $props.columns,
      data: $props.data,
      "single-line": false
    }, null, 8, ["columns", "data"])
  ]))
}